<template>
  <v-row>
    <v-col :cols="3">
      <v-list elevation="2" class="ma-2" style="height:600px;overflow:auto">
        <v-list-item-group v-model="selectedId" active-class="selected-item">
          <v-list-item value="General">
            <v-list-item-title>General</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item v-for="{ _id, name } in instruments" :key="_id" :value="name">
            <v-list-item-title>{{ name }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-dialog v-model="addDialog" width="400">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="success" class="ml-2 mb-2 mr-2" style="width:calc(100% - 14px)">
            <v-icon left>fal fa-plus</v-icon>
            Add Instrument
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Add Instrument</v-card-title>
          <v-card-text>
            <v-text-field v-model="instrToAdd" label="Name" outlined />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="addDialog = false">Cancel</v-btn>
            <v-btn :disabled="instrToAdd === ''" color="success" text @click="addInstrument()">Add Instrument</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col :cols="9">
      <v-card v-if="selectedId" class="ma-2" style="height:600px">
        <v-card-title>{{ selected.name }} Rating Options</v-card-title>
        <v-card-text class="pt-1" style="height:466px;overflow:auto;">
          <p v-if="selectedId !== 'General'">Will include all of the "General" options plus anything listed here</p>
          <v-text-field v-for="(rating, index) in selected.ratings" :key="index" :value="rating" :label="'Rating ' + (index + 1)" outlined dense @change="(val) => updateRating(val, index)">
            <template v-slot:append-outer>
              <v-btn icon color="error" style="margin-top:-6px" @click="removeRating(index)">
                <v-icon>fas fa-times-circle</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="addRating()">
            <v-icon left>fal fa-plus</v-icon>
            Add Rating
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else class="ma-2 pt-2" style="height:600px;">
        <v-card-text>
          <p>Select "General" or an instrument on the left to view/edit the ratings for that option. All ratings will be presented to the judges as a slider with values from 0 to 10.</p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup (props, { root }) {
    const setupService = root.$feathers.service('student/music/jury-setup')

    const ratings = ref([])
    const instruments = ref([])
    const selectedId = ref('')
    const selected = computed(() => {
      if (selectedId.value === 'General') {
        return { name: 'General', ratings: ratings.value }
      }
      const rec = instruments.value.find(({ name }) => name === selectedId.value)
      if (rec) return rec
      return { name: '', ratings: [] }
    })

    const addDialog = ref(false)
    const instrToAdd = ref('')

    async function addInstrument () {
      try {
        const rec = await setupService.patch(props.id, { $push: { instruments: { name: instrToAdd.value } } })
        instruments.value = rec.instruments
        instrToAdd.value = ''
        addDialog.value = false
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Instrument Added Successfully' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error Adding Instrument: ' + e })
      }
    }

    watch(() => props.id, async () => {
      if (props.id == null || props.id === '') return
      const rec = await setupService.get(props.id)
      ratings.value = rec.ratings
      instruments.value = rec.instruments
    })

    async function addRating () {
      if (selectedId.value === '' || selectedId.value == null) return
      const patch = {}
      const query = {}
      if (selectedId.value === 'General') {
        patch.$push = { ratings: '' }
      } else {
        patch.$push = { 'instruments.$[instr].ratings': '' }
        query.arrayFilters = [{ 'instr.name': selectedId.value }]
      }
      await doUpdate(patch, query)
    }
    async function updateRating (val, index) {
      if (selectedId.value === '' || selectedId.value == null) return
      const patch = {}
      const query = {}
      if (selectedId.value === 'General') {
        patch['ratings.' + index] = val
      } else {
        patch['instruments.$[instr].ratings.' + index] = val
        query.arrayFilters = [{ 'instr.name': selectedId.value }]
      }
      await doUpdate(patch, query)
    }
    async function removeRating (index) {
      if (selectedId.value === '' || selectedId.value == null) return
      const patch = {}
      const query = {}
      if (selectedId.value === 'General') {
        ratings.value.splice(index, 1)
        patch.ratings = ratings.value
      } else {
        const arr = [ ...selected.value.ratings ]
        arr.splice(index, 1)
        patch['instruments.$[instr].ratings'] = arr
        query.arrayFilters = [{ 'instr.name': selectedId.value }]
      }
      await doUpdate(patch, query)
    }
    async function doUpdate (patch, query) {
      if (JSON.stringify(patch) !== '{}') {
        const rec = await setupService.patch(props.id, patch, { query })
        ratings.value = rec.ratings
        instruments.value = rec.instruments
      }
    }

    return {
      ratings,
      instruments,
      selectedId,
      selected,
      addDialog,
      instrToAdd,
      addInstrument,
      addRating,
      updateRating,
      removeRating
    }
  }
}
</script>
<style>
.theme--light .selected-item {
  background-color: #B7DFFF
}
.theme--dark .selected-item {
  background-color: #193264
}
</style>
